import styled from 'styled-components';

export const EventLabel = styled.span`
  text-transform: uppercase;
  font-size: 10px;
  font-weight: 700;
  background: #eee;
  color: #777;
  padding: 4px 7px;
  border-radius: 3px;
  margin-left: 10px;
  margin-top: -2px;
  vertical-align: middle;
`;
