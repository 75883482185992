import React from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';
import Confetti from 'react-confetti';
import { MapPin } from 'react-feather';
import { isMobile } from 'react-device-detect';
import moment from 'moment';
import 'moment/locale/pt';

import Layout from '../components/Layout';
import Header from '../components/Header';
import Breaker from '../components/Breaker';
import eventsData from '../data/events.json';
import SEO from '../components/Seo';
import { EventLabel } from '../styles/events';

import SalverIcon from './icons/Salver.svg';

const confettiConfig = {
  width: typeof window !== 'undefined' && window.innerWidth,
  height: typeof window !== 'undefined' && window.innerHeight,
  numberOfPieces: 50,
  recycle: false,
  opacity: .5
}

const Label = ({ text, style }) => <EventLabel style={{ ...style }}>{text}</EventLabel>;

const Eventos = () => (
  <>
    <Confetti {...confettiConfig} />

    <Layout>
      <SEO title="Eventos" keywords={[`paleo`, `health`, `wellness`, `coach`, `fitness`, `coaching`, `chef`, `mindfulness`, `desporto`, `gym`, `ginasio`, `eventos`, `workshop`]} />
      <Header />

      <Grid>
        <Row>
          <Col md={12}>
            <div style={{
              textAlign: 'center',
              marginTop: 70
            }}>
              <SalverIcon style={{
                width: 80,
                fill: '#faab06'
              }} />
            </div>

            <div style={{
              fontFamily: 'Muli',
              fontSize: 72,
              lineHeight: 1,
              fontWeight: 700,
              textAlign: 'center',
              color: '#faab06'
            }}>
              Eventos
            </div>
          </Col>
        </Row>

        <Breaker />

        <Row>
          <Col md={12}>
            {eventsData.map((event, i) => {
              const isComing = moment(event.startAt).isAfter(
                moment().subtract(1, 'day')
              );
              const isToday = moment(event.startAt).isSame(
                moment(), 'day'
              );

              return (
                <div
                  style={{
                    fontSize: 15,
                    borderBottom: '1px #ebebeb solid',
                    padding: '8px 0',
                    opacity: !isComing && '.3'
                  }}
                  key={i}
                >
                  <Row>
                    <Col md={3} style={{ marginBottom: isMobile ? 10 : 0 }}>
                      {event.missingTime ? (
                        `${moment(event.startAt).format('DD MMM YYYY')} — Brevemente`
                      ) : (
                        `${moment(event.startAt).format('DD MMM YYYY HH:mm')} — ${moment(event.endAt).format('HH:mm')}`
                      )}

                      {isComing && !isToday && <Label text={moment(event.startAt).fromNow()} />}
                      {isToday && <Label text={`Hoje`} style={{ background: '#2eb054', color: '#fff' }} />}
                    </Col>

                    <Col md={5} style={{ marginBottom: isMobile ? 10 : 0 }}>
                      <strong>
                        {event.title}
                      </strong>
                    </Col>

                    <Col md={3} xs={7} style={{ marginBottom: isMobile ? 10 : 0 }}>
                      <MapPin style={{
                        margin: '-5px 5px 0 0',
                        verticalAlign: 'middle',
                        width: 20
                      }} />
                      {event.location}
                    </Col>

                    <Col md={1} xs={3} style={{ marginBottom: isMobile ? 10 : 0 }}>
                      {event.facebookUrl && isComing && (
                        <a
                          href={event.facebookUrl}
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{
                            color: '#faab06'
                          }}
                        >
                          + Detalhes
                        </a>
                      )}
                    </Col>
                  </Row>
                </div>
              )
            })}
          </Col>
        </Row>
      </Grid>
    </Layout>
  </>
);

export default Eventos;
